<template lang="pug">
.main-wrapper
    Dialog(header='Adicionar Anexo' :visible.sync='dialog.adicionar' :modal='true')
        form(@submit.prevent='adicionar()')
            .p-col-12
                label.form-label Título: *
                InputText(type='text' v-model="tituloNovoAnexo" required)

            .p-col-12
                div
                    label.form-label Tipo: *

                    div(style="display: flex; justify-content: space-between")
                        Dropdown(v-model="tipo" :options="tipos" optionLabel="name" placeholder="Selecione o tipo" required style="width: 80%")
                        Button(icon='jam jam-pencil' style='max-width:160px' @click="$router.push('/anexos/editar-tipos')")

            .p-col-12
                FileUpload(name="contents" url="./upload.php" @upload="" :multiple="false" accept="*/*" :maxFileSize="1000000")
                    template(#empty)
                        p Arraste e solte a imagem aqui para realizar upload
            .ta-center.my-4
                Button(label='Adicionar' type="submit")

    Dialog(header='Remover Anexo' :visible.sync='dialog.apagar' :modal='true')
        p Deseja remover o anexo?
        .ta-center.my-4
            Button.p-button-danger(label='Remover' @click='remove()')

    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Anexos</b>

    .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialog.adicionar = true")

    ProgressBar(v-if="waiting || waitingApagar" mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Anexos')
            DataTable(:autoLayout='true' :value="list.rows" responsiveLayout="scroll")
                Column(headerStyle='width: 5%; font-weight: bold' field='id' header='id')

                Column(field='title' header='Título')

                Column(field='title' header='Tipo de anexo')

                Column(headerStyle='width: 15%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Download'"
                                icon="jam jam-download"
                                @click="view(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar(props.data.id)"
                            )
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss" scoped>
.dialogApagar {
    .p-progress-spinner {
        width: 29px;
        height: auto;
    }
}
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias'
import ProgressBar from "primevue/progressbar";
import Dialog from 'primevue/dialog'
import DataView from 'primevue/dataview'
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import FileUpload from 'primevue/fileupload';
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

export default {
    name: "PerguntasFrequentes",
    components: { ProgressBar, Paginator, Dialog, DataView, Panel, DataTable, Column, Button, Tooltip, FileUpload, InputText, Dropdown },
    directives: { tooltip: Tooltip },
    data() {
        return {
            waiting: false,
            waitingApagar: false,
            dialog: {
                apagar: false,
                adicionar: false
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: 10,
                count: 0
            },
            idASerApagado: '',
            list: [],
            tokenAPI: '',
            tituloNovoAnexo: '',
            tipos: [],
            tipo: ''
        };
    },
    created() {
        //Login na nova API
        Noticias.login().then(result => {
            this.tokenAPI = result;
            this.getList()

            Noticias.getAttachmentTypes(this.tokenAPI)
            .then(response => {
                if(! response) this.tipos = []
                else this.tipos = response.rows
            })
        })
    },
    methods: {
        getList() {
            this.waiting = true
            Noticias.getAttachments(this.tokenAPI)
            .then(response => {
                if(! response) this.list = []
                else {
                    // Ordena a lista pelo id em ordem decrescente
                    response.rows.sort((a, b) => {
                        if(a.id < b.id) return 1
                        else return -1
                    })
                    this.list = response
                    this.paginator.count = response.total 
                    // console.log(response.total);
                }
                this.waiting = false
            })
        },
        dialogApagar(id) {
            this.idASerApagado = id
            this.dialog.apagar = true
        },
        onPage(event) {
            this.waiting = true;
            this.paginator.page = event.page + 1;
            Noticias.getAttachments(this.tokenAPI, this.paginator.page, 'id-desc')
            .then(response => {
                if(! response) this.list = []
                else {
                    // Ordena a lista pelo id em ordem decrescente
                    // response.rows.sort((a, b) => {
                    //     if(a.id < b.id) return 1
                    //     else return -1
                    // })
                    this.list = response
                    this.paginator.count = response.total 
                }
                this.waiting = false
            })
        },
        adicionar() {
            const data = {
                title: this.tituloNovoAnexo,
                attach: 'teste',
                attachment_type_id: this.tipo.id
            }

            this.waiting = true
            Noticias.saveAttachment(data, this.tokenAPI).then(response =>  {
                this.dialog.adicionar = false
                if(! response)
                    this.$toast.error('Não foi possível adicionar o anexo.', { duration: 3000 })
                else {
                    this.tituloNovoAnexo = ''
                    this.getList()
                }
                this.waiting = false
            })

        },
        remove() {
            this.waiting = true
            Noticias.removeAttachment(this.idASerApagado, this.tokenAPI).then(response => {
                this.dialog.apagar = false
                if(! response)
                    this.$toast.error('Não foi possível remover a notícia.', { duration: 3000 })
                else {
                    this.getList()
                    this.$toast.success('Notícia removida com sucesso.', { duration: 3000 })
                }

                this.waiting = false
            })
        },
        view(id) {
            this.$router.push(`/noticias/visualizar/${id}/`)
        },
    },

};
</script>
